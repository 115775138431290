@import "src/assets/styles/modules";

.listInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rankingsCount {
    text-align: right;
    margin: $space-xs 0;
  }
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
}
