@import "../../../assets/styles/modules";

.noData {
  margin: $space-md 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: $space-xs;
  }

  .label {
    font-size: $f-size-lg;
    font-weight: bold;
  }
}