$size-xxl: 38px;
$size-xl: 32px;
$size-lg: 24px;
$size-md: 16px;
$size-sm: 8px;
$size-xs: 4px;

// 角丸
$radius-xs: 2px;
$radius-sm: 4px;
$radius-md: 8px;
$radius-lg: 16px;
$radius-xl: 24px;

// メディアクエリ
$size-ranks: xxs, xs, sm, md, lg, xl;

$breakpoints: (
  "xs": "screen and (max-width: 400px)",
  "sm": "screen and (max-width: 567px)",
  "md": "screen and (max-width: 768px)",
  "lg": "screen and (max-width: 992px)",
  "xl": "screen and (max-width: 1200px)",
) !default;

@mixin mediaQuery($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
