@import "../../../assets/styles/modules";

p {
  margin: 0;
}

.rankingCard {
  background-color: $content-bg-color;
  border-color: $primary-color;
  border-style: solid;
  border-radius: $radius-md;
  border-width: 0.9px;
  padding: $space-xs;
  margin-bottom: $space-sm;

  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-xs;

    .title {
      font-size: $f-size-md;
      font-weight: bold;
      display: flex;
      align-items: center;
      max-width: 250px;

      p {
        display: inline-block;
        vertical-align: middle;
        color: $black;
      }
    }

    .counts {
      .itemsCount {
        color: $support-color;
        margin-bottom: $space-base;
      }

      .likesCount {
        color: $support-color;
      }
    }
  }

  .genres {
    width: 100%;

    .genre {
      color: $black;
      margin-right: $space-base;
    }
  }
}

.rankingCard:hover {
  opacity: 0.5;
}
