@import "../../../assets/styles/modules";

.sideDrawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  max-width: 300px;
  min-width: 180px;
  z-index: 200;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: $dark-gray;
  transform: translateX(100%);
  transition: 0.2s;
}

.sideDrawerOpen {
  transform: translateX(0%);
}

.menu {
  padding: $space-xl $space-sm;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-width: 180px;

  .menuItem {
    margin-bottom: $space-xl;
    text-align: center;

    a {
      font-size: $f-size-md;
      font-weight: bold;
      color: $white;
    }
  }

  .info {
    margin-bottom: $space-xl;

    a {
      font-size: $f-size-md;
      font-weight: bold;
      color: $white;
    }
  }

  .logOut {
    display: flex;
    justify-content: center;

    p {
      font-size: $f-size-md;
      font-weight: bold;
      color: $white;
    }
  }

  .snsIcons {
    color: $white;
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: $f-size-md;
  }
}
