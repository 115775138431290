@import "../../../assets/styles/modules";

.header {
  display: flex;
  justify-content: space-between;
  background-color: $primary-color;
  border-radius: 50px;
  padding: $space-xs $space-sm;
  margin-bottom: $space-sm;

  .logoGroup {
    display: flex;
    align-items: center;

    .icon {
      width: 22px;
    }

    .logo {
      width: 100px;
    }
  }

  .logoGroup:hover {
    cursor: pointer;
  }

  .icons {
    .searchIcon {
      font-size: 22px;
      color: $white;
      margin-right: $space-sm;
    }

    .menuIcon {
      font-size: 26px;
      color: $white;
    }
  }
}
