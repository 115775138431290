// Mono
$white: #fff;
$light-gray: #f2f2f2;
$medium-gray: #bdbdbd;
$dark-gray: #606060;
$black: #171717;

// Theme
$primary-color: #42c279;
$secondary-color: #2091f9;
$danger-color: #de4747;
$support-color: #cfd5df;
$special-color: #7fe441;

// UI Variables
$line-color: #e2e2e2;
$content-bg-color: #fbfff9;
$input-bg-color: #f0f5f4;
$link-color: #3f869c;
$hover-primary-color: #15b603;

// Ranking Color
$gold: #f4c315;
$silver: #8d9cb1;
$bronze: #b5936c;

// Color Pallet
$light-green: #5ee8d8;
$green: #03c1ab;
$dark-green: #03695d;

$light-blue: #8899f0;
$blue: #42a5f5;
$dark-blue: #172671;

$light-purple: #b392f0;
$purple: #6f42c1;
$dark-purple: #4c2889;

$light-yellow: #ffea7f;
$yellow: #ffd33d;
$dark-yellow: #dbab09;

$light-orange: #ffab70;
$orange: #f66a0a;
$dark-orange: #d15704;

$light-red: #f97583;
$red: #d73a49;
$dark-red: #b31d28;
