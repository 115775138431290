@import "src/assets/styles/modules";

.genreTitle {
  font-size: $f-size-lg;
  font-weight: bold;
  margin: $space-sm 0;
}

.listInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rankingsCount {
    text-align: right;
    margin: $space-xs 0;
  }
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
}