@import "../../../assets/styles/modules";

.rankingDetail {
  .rankingInfo {
    margin-bottom: $space-sm;
  }

  .rankingTitle {
    font-size: $f-size-lg;
    font-weight: bold;
    margin-bottom: $space-xs;
  }

  .rankingSubInfo {
    .creatorInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $space-xs;

      .creator {
        color: $support-color;
        margin-bottom: $space-base;
      }
    }

    .genres {
      width: 100%;

      .genre {
        color: $black;
        margin-right: $space-base;
      }
    }
  }

  .itemInput {
    border-width: 0.9px;
    border-radius: $radius-sm;
    border-color: $support-color;
    width: 100%;
    height: $size-xxl;
    margin-top: $space-sm;
  }

  .createButton {
    width: 100%;
    background-color: $secondary-color;
    color: $white;
    font-weight: bold;
    border-radius: $radius-sm;
    margin-top: $space-xs;
  }
}

.loadMoreButton {
  text-align: center;
}

.loadMoreButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
}