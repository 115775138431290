@import "src/assets/styles/modules";

.searchBar {
  border-width: 0.9px;
  border-radius: $radius-sm;
  border-color: $support-color;
  margin-bottom: $space-sm;
  width: 100%;
  height: $size-xxl;
}

.genreLabel {
  font-weight: bold;
  margin-bottom: $space-xs;
}

